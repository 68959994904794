@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.boxShadow {
  box-shadow: 0px 5px 21px 0px rgba(29, 29, 29, 0.07);
}

.boxShadow2 {
  box-shadow: 0px 5px 21px 0px #1d1d1d12;
}

.parallax {
  background-image: url('./assets//benefits.svg');

  /* The parallax scrolling effect */
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
